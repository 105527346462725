import Card from "react-bootstrap/Card";
import "./Card.css";
import montaz from "../../images/montaz.jpg";
import serwis from "../../images/serwis.jpg";
import naprawa from "../../images/naprawa.jpg";

function myCard() {
  return (
    <>
      <h1 className="title">Oferta</h1>
      <div className="line-wrapper">
        <div className="line"></div>
      </div>
      <div className="card-wrapper">
        <span>
          <Card style={{ width: "25rem" }}>
            <Card.Img
              variant="top"
              src={montaz}
              style={{ maxHeight: "320px" }}
            />
            <Card.Body>
              <Card.Title id="titleStyle">Montaż</Card.Title>
              <Card.Text id="textStyle">
                Wykonujemy montaż klimatyzacji i pomp ciepła, marki Gree i
                Rotenso
              </Card.Text>
            </Card.Body>
          </Card>
        </span>
        <span>
          <Card style={{ width: "25rem" }}>
            <Card.Img
              variant="top"
              src={serwis}
              style={{ maxHeight: "320px" }}
            />
            <Card.Body>
              <Card.Title id="titleStyle">Serwis</Card.Title>
              <Card.Text id="textStyle">
                Wykonujemy serwisowanie okresowe zamontowanych przez siebie
                urządzeń. Posiadamy wszelkie niezbędne do zachowania gwarancji
                producenta uprawnienia i certyfikaty. Wykonujemy serwisy
                gwarancyjne i pogwarancyjne dla Gree i Rotenso, oraz
                pogwarancyjne dla innych marek.
              </Card.Text>
            </Card.Body>
          </Card>
        </span>
        <span>
          <Card style={{ width: "25rem" }}>
            <Card.Img
              variant="top"
              src={naprawa}
              style={{ maxHeight: "320px" }}
            />
            <Card.Body>
              <Card.Title id="titleStyle">Naprawa</Card.Title>
              <Card.Text id="textStyle">
                Dokonujemy napraw urzadzeń chłodniczych, gwarancyjnych i
                pogwarancyjnych dla Gree i Rotenso, oraz pogwarancyjnych dla
                innych marek.
              </Card.Text>
            </Card.Body>
          </Card>
        </span>
      </div>
      <div className="serwis-wrapper-media">
        <h1 id="titleStyle" className="leftStyleText">Montaż</h1>
        <p id="textStyle">
          Wykonujemy montaż klimatyzacji i pomp ciepła, marki Gree i Rotenso
        </p>
        <h1 id="titleStyle" className="rightStyleText">Serwis</h1>
        <p id="textStyle">
          Wykonujemy serwisowanie okresowe zamontowanych przez siebie urządzeń.
          Posiadamy wszelkie niezbędne do zachowania gwarancji producenta
          uprawnienia i certyfikaty. Wykonujemy serwisy gwarancyjne i
          pogwarancyjne dla Gree i Rotenso, oraz pogwarancyjne dla innych marek.
        </p>
        <h1 id="titleStyle" className="leftStyleText">Naprawa</h1>
        <p id="textStyle">
          Dokonujemy napraw urzadzeń chłodniczych, gwarancyjnych i
          pogwarancyjnych dla Gree i Rotenso, oraz pogwarancyjnych dla innych
          marek.
        </p>
      </div>
    </>
  );
}

export default myCard;
